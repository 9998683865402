@import 'settings.scss';

.login-form {
    .login-error {
        background-color: $red;
        color: $white;
        margin: 0;
        font-size: 14px;
        text-align: left;
        overflow: hidden;
        max-height: 100px; // arbitrary
        padding: 17px 16px;
        transition: max-height 0.15s ease-in;
        transition: padding 0.15s ease-in;
        -webkit-transition: max-height 0.15s ease-in;
        -webkit-transition: padding 0.15s ease-in;
    }
    .error-hidden {
        display: block;
        overflow: hidden;
        font-weight: bold;
        padding: 0 17px;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        transition: padding 0.15s ease-out;
        -webkit-transition: max-height 0.15s ease-in;
        -webkit-transition: padding 0.15s ease-in;
    }

    form {
        position: relative;

        p {
            font-size: 14px;
            margin: 0;
        }
        
        input[type=text], input[type=password] {
            font-family: "Chivo", sans-serif;
            outline: 0;
            background: $grey1;
            border: 1px solid $grey2;
            border-radius: 2px;
            width: 100%;
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            margin-top: 16px;
        }

        input[type=text]::placeholder, input[type=password]::placeholder {
            position: relative;
            color: $grey3;
        }
            
        i {
            font-size: 18px;
        }

        .submit-icon {
            color: $grey3;
            position: absolute;
            bottom: 4px;
            right: 4px;
            padding: 12px;
            cursor: pointer;
            transition: 0.3s;
            -webkit-transition: 0.3s;
        }

        .submit-icon:hover {
            color: $black;
            transition: 0.3s;
            -webkit-transition: 0.3s;
        }
    }
}

