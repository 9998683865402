@import 'settings.scss';

.donation-banner {
    display: block;
    text-align: center;

    margin-bottom: 10px;
    margin-left: -6px;
    margin-right: -6px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;

    color: white;
    text-decoration-line: none;
    font-size: 14px;

    @media (min-width: $mobileBreakpoint) {
        margin-bottom: 10px;
        
        padding: 10px;
        font-size: 18px;
    }
}

.top-banner {
    margin-top: 20px;

    @media (min-width: $mobileBreakpoint) {
        margin-top: 30px;
    }
}