@import 'settings';

.video-wrapper {
    position: relative;
    margin: 0;
    padding-top: 56.25%;
}

.video-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    
    @media (min-width: $mobileBreakpoint) {        
        flex-direction: row;
        justify-content: space-between;

        .callToActionContainer {
            flex: (1/4);
            padding-right: 10px;
        }

        .videoMessageContainer {
            flex: (1/2);
            text-align: center;
        }
    }
}