@import 'settings.scss';

.feedback-input {
    textarea {
        width: 100%;
        height: 80px;
        padding: 6px;
        margin: 0 0 20px 0;
        font-family: inherit;
        font-size: inherit;
        color: $grey6;

        display: inline-block;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        //border: none;
    }
}