@import 'settings.scss';

.guest-section {
    .video-message.guest-list-header {
        font-weight: bold;
        font-size: 16px;
    }
    .video-message.guest-list-subtitle {
        font-weight: normal;
        font-size: 12px;
    }

    @media (min-width: $mobileBreakpoint) {
        .video-message.guest-list-header {
            font-size: 24px;
        }
        .video-message.guest-list-subtitle {
            font-size: 16px;
        }
    }
}