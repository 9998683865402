@import 'settings.scss';

.viewlogy {
    margin: 0 4px;

    .viewlogy-login {
        position: relative;
        width: 100%;
        min-height: 222px;
        margin-bottom: 40px;
    }

    .subheader {
        h3 {
            padding: 0 20px;
            text-wrap: balance;
        }
    }

    p.video-message {
        font-size: 14px;
        padding: 0 20px;
    }

    .streams-navigation {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
    }

    .stream-tab {
        font-size: 15px;
        align-self: flex-end;
        width: 100%;
        cursor: pointer;
        padding: 6px;
        border-bottom: 1px solid $grey2;
        line-height: 18px;
        letter-spacing: .5px;

        &.single {
            cursor: auto;
        }
    }

    .stream-tab.selected {
        font-weight: bold;
    }

    @media (min-width: $mobileBreakpoint) {
        margin: 0 auto;
        padding: 0 6px !important;
        max-width: 1268px;

        h2 {
            font-size: 32px;
        }

        .viewlogy-login {
            margin-top: 40px;
        }

        .streams-navigation {
            flex-direction: row;
        }

        .stream-tab {
            font-size: 18px;
            line-height: unset;
            padding: 12px;
        }

        p.video-message {
            font-size: 24px;
            margin: 28px 0;
        }
    }
}