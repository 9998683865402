// store global variables here

$mobileBreakpoint: 768px;

$black: #000;
$white: #fff;
$teal: #16394e;
$red: #e93f33;
$green: #306f5c;
$yellow: #f7d308;

// viewlogies accent color
$blue: #253746;

$grey1: #fafafa;
$grey2: #e1e1e1;
$grey3: #afafaf;
$grey4: #7d7d7d;
$grey5: #646464;
$grey6: #323232;
$grey7: #191919;

.close-icon:hover {
    cursor: pointer;
}
