@import 'settings';

.mobileplaceholder-container {
    width: 100%;
    top: 0;
    padding-top: 56.25%;
    background-color: $black;
    position: absolute;
    border: 1px solid $black;

    &.hidden {
        height: 0;
        padding-top: 0;
    }

    .mobileplaceholder {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $white;

        h2 {
            color: $white;
            font-size: 14px;
            margin: 0 0 12px 0;
        }

        p {
            font-size: 48px;
            margin: 0;
        }
    }

    .live-caption {
        position: absolute;
        left: 8px;
        bottom: 6px;
        display: flex;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial;
        color: $grey2;

        .circle {
            border: 1px solid $grey2;
            //background-color: $white;
            margin: 4px 4px 4px 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
    }


    @media (min-width: $mobileBreakpoint) {
        .mobileplaceholder {
            h2 {
                font-size: 16px;
            }

            p {
                font-size: 72px;
            }

            .mobileplaceholder-dhm {
                .mobileplaceholder-time-unit {
                    width: 130px;
                    margin: 0 4px;

                    h3 {
                        font-size: 22px;
                        margin: 40px 0 6px 0;
                    }
                }
            }
        }
    }
}