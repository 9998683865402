@import 'settings.scss';

.guest-form-container {
    .guest-form-description {
        color: $grey5;
        margin-bottom: 32px;
        font-size: 15px;
        display: flex;
        justify-content: center;
    }

    .guest-form {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        max-width: 500px;
        margin: 20px auto 0 auto;

        .guest-form-input, .guest-submit-button {
            padding: 8px;
            font-family: "Chivo", sans-serif;
        }

        .guest-form-input.last-input {
            margin-bottom: 28px;
        }

        .guest-form-input {
            margin-bottom: 12px;
            border-radius: 2px;
            border: 1px solid $grey3;
        }

        .guest-form-input.invalid {
            border: 1px solid $red;
        }

        .guest-form-recaptcha-container {
            display: none;
        }

        .guest-form-recaptcha {
            margin-bottom: 20px;
        }

        .guest-submit-button {
            margin-bottom: 20px;
            text-transform: uppercase;

            .button-content {
                width: 100%;
                text-align: center;
            }
        }


        .placeholder {
            font-size: 11px;
            text-transform: uppercase;
            text-align: left;
            margin-bottom: 2px;
            color: $grey6;
        }

        .field-error-message {
            margin: -10px 0 14px 0;
            padding: 0;
            text-transform: uppercase;
            font-size: 10px;
            color: #e93f33;
            text-align: left;
        }

        .error-message {
            color: $red;
            margin-bottom: 6px;
            text-align: left;
            font-size: 14px;
        }

        .guest-form-disclaimer {
            color: $grey5;
            margin: 2px 0;
            font-size: 10px;
        }

        @media (min-width: $mobileBreakpoint) {
            .guest-form-disclaimer {
                margin: 8px 0;
                font-size: 12px;
            }
        }
    }

    .video-message.guest-form-header {
        font-weight: bold;
        font-size: 16px;
    }
    .video-message.guest-form-subtitle {
        font-weight: 300;
        font-size: 14px;
        margin-top: 0px;
    }

    @media (min-width: $mobileBreakpoint) {
        .video-message.guest-form-header {
            font-size: 24px;
        }
        .video-message.guest-form-subtitle {
            font-size: 20px;
        }
        .guest-form-description {
            font-size: 16px;
        }
    }
}