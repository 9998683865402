@import 'settings.scss';

.player-wrapper {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;

    //border: 1px solid $grey4;
    background-color: $grey7;

    &.hidden {
        height: 0;
        padding-top: 0;
    }
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}