@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:700&display=swap');
@import 'settings.scss';

body {
    margin: 0;
    font-family: 'Chivo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $black;
}

body, html, #root, .app-wrapper {
    height: 100%;
}

h1 {
    font-family: 'Comfortaa', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 16px;
}
h2 {
    font-family: 'Chivo', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    color: $teal;
}
h3 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: .2em;
}
p {
    font-size: 13px;
    margin-top: 15px;
}

hr {
    border: 0;
    height: 1px;
    background: $grey2;
    margin: 20px 0;
}

@media (min-width: $mobileBreakpoint) {
    h1 {
       font-size: 24px;
    }
}

// global classes
.main-content {
    padding: 0;
}

.centered {
    text-align: center;
}

.emphasized {
    font-family: 'Chivo', sans-serif;
    font-style: italic;
}

.hidden {
    display: none !important;
}

.error-message {
    color: $red;
}

.submit-button {
    font-family: "Chivo", sans-serif;
    text-transform: uppercase;
    outline: 0;
    border: none;
    border-radius: 4px;
    padding: 12px 45px;
    background-color: $white;
    color: $black;
    font-size: 14px;
    cursor: pointer;
}

.action-container {
    padding: 42px 20px 20px 20px;
    text-align: center;
    height: 100%;
    background-color: $grey2;

    @media (min-width: $mobileBreakpoint) {
        padding: 0 20px;

        .header {
            margin: 0;
        }

        h1 {
            padding: 20px 0;
            margin: 0;
        }
    }
}

.action {
    h2 {
        font-size: 18px;
        margin-bottom: 28px;
    }

    hr {
        background: $grey3;
        max-width: 1268px;
        margin: 20px auto;
    }

    p {
        font-size: 16px;
    }

    button {
        margin-bottom: 8px;
    }

    .action-input {
        width: 100%;
        margin: 20px auto 0 auto;

        label, button {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 15px 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        @media (min-width: $mobileBreakpoint) {
            width: 400px;
        }
    }


}

@media (min-width: $mobileBreakpoint) {
    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 46px;
    }
    h3 {
        font-size: 16px;
    }
}
