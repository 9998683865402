@import 'settings.scss';

.centered-content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey2;

    text-align: center;

    .header {
        background-color: unset;
        text-align: center;
    }

    .centered-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .top-centered-content {
        margin-top: 42px;

        @media (min-width: $mobileBreakpoint) {
            margin-top: 0;
        }
    }

}
