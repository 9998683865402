@import 'settings.scss';

.resources {
    text-align: left;
    margin: 6px;

    .resources-card {
        background-color: $grey1;
        display: flex;
        flex-direction: column;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 18px;
        }

        img {
            height: 160px;
            object-fit: cover;
            width: 100%;
        }

        .resources-card-text {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 12px 20px 20px 20px;
        }

        h2 {
            color: $black;
            font-size: 18px;
            margin: 0;
        }
        p {
            margin: 16px 0;
        }

        div.resources-link-container {
            margin-top: auto;
        }
        a.resources-link {
            display: inline-block;
            font-weight: bold;
            text-decoration: none;
        }
    }

    @media (min-width: $mobileBreakpoint) {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;

        position: relative;
        width: 100%;

        margin: 40px 0 60px 0;

        .resources-card {
            flex: 1;
            margin-bottom: 0;

            &:first-child {
                margin-right: 18px;
            }
            &:nth-last-child(2) {
                margin-right: 18px;
            }
            &:not(:last-child) {
                margin-bottom: 0;
            }

            h2 {
                font-size: 26px;
            }
            p {
                margin: 22px 0;
            }
            p, a {
                font-size: 16px;
            }

            img {
                height: 180px;
            }
        }
    }
}

