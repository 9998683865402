@import 'settings';
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@100;700&display=swap');

.callToAction {
    position: relative;
    padding: 0px;
    
    .ctaText {
        font-size: 14px;
        font-family: 'Chivo', sans-serif;
        letter-spacing: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    .ctaTitle {
        text-transform: uppercase;
        font-weight: 700;
    }

    .ctaSubtitle {
        font-weight: 100;
    }

    .ctaButton {
        padding: 5px;
        text-decoration: 'none';
        font-family: 'Chivo, sans-serif';
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .ctaTextContainer {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    @media (min-width: $mobileBreakpoint) {        
    
        // see countdown.scss for button positioning in full-screen on countdown
        
        .ctaText {
            font-size: 20px;
        }

        .ctaTextContainer {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        .ctaButton {
            padding: 15px;
        }
    }
}