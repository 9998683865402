@import 'settings';

.login-container {
    width: 300px;
    margin: 0 auto 24px auto;

    .lock-container {
        width: 100%;

        .lock {
            width: 100px;
            height: 100px;
            margin: 0 auto;

            @media (min-width: $mobileBreakpoint) {
                width: 120px;
                height: 120px;
            }
        }
    }
}
