.download-cta-message {
    margin-bottom: 20px;
}

.download-cta {
    display: flex;
    align-items: center;
    flex-direction: column;

    .download-cta-button {
        min-width: 80px;
        display: inline-block;
        text-align: center;
        //margin-bottom: 20px;

        .button-content {
            text-align: center;
            //width: 100%;
        }
    }

    .download-cta-button:not(:last-child) {
        margin-bottom: 16px;
    }
}