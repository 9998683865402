@import 'settings.scss';

.client-header {
    margin-top: 12px;

    hr {
        margin-top: 12px;
    }

    img {
        max-height: 50px;
        max-width: 100%;
    }

    @media (min-width: $mobileBreakpoint) {
        margin-top: 28px;

        hr {
            margin-top: 20px;
        }

        img {
            max-height: 85px;
        }
    }
}
