@import 'settings.scss';

.guest-list-container {
    .guest-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .guest-container {
            width: 50%;
            box-sizing: border-box;
            padding: 6px 4px;
        }

        .guest-name {
            color: $grey5;
            font-size: 15px;
        }

        .guest-date {
            font-size: 13px;
        }

        @media (min-width: $mobileBreakpoint) {
            .guest-container {
                width: 25%;
            }

            .guest-name {
                color: $grey5;
                font-size: 16px;
            }

            .guest-date {
                font-size: 14px;
            }
        }
    }

    button.load-more-button {
        border: none;
        background: none;
        outline: none;
        text-decoration: underline;
        cursor: pointer;
        color: #0000ee;
        font-size: 14px;
    }

    button.load-more-button.disabled {
        color: $grey3;
        cursor: default;
    }
}