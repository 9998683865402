@import 'settings.scss';

.contact-us {
    .contact-us-content {
        padding: 0 32px;
    }

    h2 {
        font-size: 16px;
    }

    @media (min-width: $mobileBreakpoint) {
        .contact-us-content {
            padding: 12px;
        }

        h2 {
            font-size: 24px !important;
        }    
    }
}
