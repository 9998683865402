@import 'settings';

.slideshow-upload {

    .video-wrapper {
        padding-top: 0
    }
    
    .player-wrapper {
        position: relative;
        margin: 0 auto;
    }

    .slideshow-upload-input {
        input[type="file"] {
            visibility: hidden;
        }
    }

    @media (min-width: $mobileBreakpoint) {
        .player-wrapper {
            width: 540px;
            padding-top: 312px;
        }

        .slideshow-upload-input {
            width: 400px;
        }
    }
}