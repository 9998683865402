@import 'settings.scss';

.login-form {
    .input-cta {
        font-size: 15px;
        margin: 10px 0 6px 0;
    }

    .input-cta-secondary {
        color: $red;
        font-style: italic;
    }

    .input-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input {
        text-align: left;

        .help-button-container {
            max-width: 264px;

            p, button {
                font-style: italic;
                margin: 0;
                padding: 3px 6px;
            }
        }

        .help-button {
            cursor: pointer;
            color: $grey5;
            text-decoration: underline;
            border: none;
            background-color: unset;
            outline: none;
            font-size: 12px;
        }

        .help-button-message {
            color: $red;
        }

        .pin-error {
            color: $red;
            display: block;
            margin-bottom: 6px !important;
        }

        .pin-error-hidden {
            display: none;
        }

        //.fa-sort-down {
        //    font-size: 16px;
        //    margin-left: 3px;
        //}
    }

    .six-digit-input {
        position: relative;
    }

    .react-code-input {
        input {
            background-color: $grey1 !important;
            box-shadow: unset !important;
            border-radius: 2px !important;
            font-size: 16px !important;
            font-family: 'Chivo', sans-serif !important;
            padding: 0 0 0 10px !important;
        }

        // hide input arrows
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
        input[type=number] {
            -moz-appearance: textfield !important;
        }

        input:nth-child(3) {
            margin-right: 17px !important;
        }
    }

    .dash-separator {
        position: absolute;
        top: 16px;
        left: 136px;
    }

    @media (min-width: $mobileBreakpoint) {
        .react-code-input {
            input {
                font-size: 20px !important;
            }
        }

        .input-cta {
            font-size: 16px;
            margin: 15px 0;
        }

        .help-button {
            font-size: 14px;
        }
    }
}