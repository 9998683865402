@import 'settings';
@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@100;700&display=swap');

.countdown-container {
    width: 100%;
    top: 0;
    padding-top: 56.25%;
    background-color: $black;
    position: absolute;
    border: 1px solid $black;

    &.hidden {
        height: 0;
        padding-top: 0;
    }

    .countdown {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        color: $white;

        h2 {
            color: $white;
            font-size: 14px;
            margin: 0 0 12px 0;
        }

        .countdown-number {
            font-size: 48px;
            margin: 0;
        }
        
    }

    .live-caption {
        position: absolute;
        left: 8px;
        bottom: 6px;
        display: flex;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial;
        color: $grey2;

        .circle {
            border: 1px solid $grey2;
            //background-color: $white;
            margin: 4px 4px 4px 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }
    }

    .countdown-dhm {
        display: flex;
        justify-content: space-between;

        .countdown-time-unit {
            text-align: center;
            margin: 0 8px;
            width: 82px;

            h3 {
                font-size: 18px;
                letter-spacing: unset;
                margin: 8px 0 2px 0;
            }
        }
    }

    @media (min-width: $mobileBreakpoint) {
        .countdown {
            h2 {
                font-size: 16px;
            }

            .countdown-number {
                font-size: 72px;
            }

            .countdown-dhm {
                .countdown-time-unit {
                    width: 130px;
                    margin: 0 4px;

                    h3 {
                        font-size: 22px;
                        margin: 40px 0 6px 0;
                    }
                }
            }

            .callToAction {
                position: absolute;
                top: 15px;
                right: 25px;
            }
        }
    }
}