@import 'settings.scss';

.header {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    text-align: left;

    a {
        color: inherit;
        text-decoration: inherit;
    }
    
    @media (min-width: $mobileBreakpoint) {
        text-align: center;
        margin: 22px 0;
        position: relative;
        height: auto;
    }
}

.header-black {
    background-color: $white;
    opacity: .8;
}

.header-black-no-background {
}

.header-white {
    color: $white;
    font-weight: bold;
}
