.proceed-cta-message {
    line-height: 26px;
}

.proceed-cta-container {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;

    button {
        min-width: 80px;

        .button-content {
            text-align: center;
            width: 100%;
        }
    }

    button:first-child {
        margin-right: 20px;
    }
}