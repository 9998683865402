@import 'settings';

.viewlogies-button {
    padding: 15px;
    font-family: 'Chivo', sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #ffffff;
    border: 2px solid $blue;
    color: $blue;
    font-size: 15px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    transition: 0.1s;
    display: flex;

    .select-container {
        margin: 0 0 0 12px;

        select {
            margin-right: 0;
        }
    }

    .button-content {
        height: 100%;
        margin: auto 0;
    }
}
.viewlogies-button:disabled {
    cursor: auto;
    opacity: .6;
}
.viewlogies-button:not(:disabled):hover {
    background: $blue;
    color: $white;
    transition: 0.1s;
}

.viewlogies-button.secondary-button {
    padding: 2px 8px;
    font-family: 'Chivo', sans-serif;
    text-transform: uppercase;
    outline: 0;
    border: 2px solid $green;
    border-radius: 12px;
    color: $green;
    font-size: 12px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    transition: 0.1s;
    display: flex;

    .select-container {
        margin: 0 0 0 12px;

        select {
            margin-right: 0;
        }
    }
}
.viewlogies-button.secondary-button:hover {
    background: $green;
    color: $white;
    transition: 0.1s;
}

a.viewlogies-button {
    text-decoration: none;
}
