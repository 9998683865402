@import 'settings';

.overlay-container {
    top:0;
    left:0;
    right:0;
    bottom:0;
    //background-color: $black;
    position: absolute;
    //border: 1px solid $black;
    display: flex;

    &.hidden {
        height: 0;
        padding-top: 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        text-align: left;
        color: $black;
        pointer-events: none;
        z-index: 99;
        -webkit-animation-duration: 300s;
        animation-duration: 300s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        .name {
            h2 {
                color: $green;
                border: 4px solid $white;
                background-color: $white;
                font-size: 20px;
                font-weight: bold;
                margin: 0 0 0px 0;
                margin-left: 100px;
            }
        }
        .description {
            h2 {
                color: $white;
                border: 4px solid $green;
                background-color: $green;
                font-size: 14px;
                font-weight: bold;
                margin-top: 0px;
                margin-bottom: 70px;
                margin-left: 100px;
            }
        }
    }
}

/* Mobile styles */
@media only screen and (max-width: 767px) {
    .overlay-container {
        position: relative;
        display: block;
        height: auto;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        padding: 20px;
        height: 100%;
        justify-content: center;
        align-items: center;

        .name {
            h2 {
                margin-left: 0 !important; 
                font-size: 14px !important;
                text-align: center;
            }
        }

        .description {
            h2 {
                margin-left: 0 !important;
                font-size: 10px !important;
                text-align: center;
            }
        }
    }
}

@-webkit-keyframes fadeOut {
    0% {opacity: 0;}
    55% {opacity: 0;}
    60% {opacity: 1;}
    95% {opacity: 1;}
    100% {opacity: 0;}
 }
 
 @keyframes fadeOut {
    0% {opacity: 0;}
    55% {opacity: 0;}
    60% {opacity: 1;}
    95% {opacity: 1;}
    100% {opacity: 0;}
 }

 
 .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
 }
